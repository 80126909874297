.navbar-background-color {
    background-color: white;
}

.logo-color {
    color: #FFA626;
}

.navPadding {
    padding: 20px;
}

.nav-link-custom {
    font-family: 'roboto';
    color: #8f8ca8;
    text-transform: capitalize;
    font-weight: 500;
    line-height: 24px;
    font-size: 16px;
    border-bottom: 1px dotted transparent;
    margin: 10px 15px;
    cursor: pointer;
}
.nav-link-custom:hover {
    border-bottom: 1px dotted black;
    color: purple;
}

.cursor-pointer {
    cursor: pointer;
}

.color-white {
    color: white;
}
