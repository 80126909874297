.smallScreenPadding {
    padding-bottom: 0;
}
.smallScreenMargins {
    margin-top: 0;
    margin-left: 0;
}

.header1 {
    font-family: 'roboto';
    font-size: 48px;
    font-weight: 700;
    text-transform: capitalize;
}

.darkMode {
    background-color: #ececf6;
}

@media only screen and (max-width: 992px) {
    .smallScreenPadding {
        padding-bottom: 60px;
    }
    .smallScreenMargins {
        margin-top: 55px;
        margin-left: 12px;
    }
}
