.footer {
	font-family: 'roboto';
	font-size: 12px;
	line-height: 24px;
	color: white;
	background-image: linear-gradient(45deg,#3a2772 30%,#190b2e 75%);
}

.footerBackgroundColor {
	/*background-color: #120F26;*/
}

.footerPadding {
	padding-top: 6em;
	padding-bottom: 3em;
}

.logo {
	color: white;
	font-size: 32px;
	line-height: 110px;
	font-family: 'roboto';
}
.orange {
	color: orange;
}

.separation-border {

}

.color-white {
	color: white;
}

.whiteLink {
	color: white;
	cursor: pointer;
}
.whiteLink:hover {
	color: #ffa500;
	text-decoration: none;
}

.copyright {
	/*margin-bottom: 100px;*/
	margin-top: 35px;
}

.popup {
    position: fixed;
    left: 5em;
    top: 0em;
    z-index: 2020;
    overflow: hidden;
    transition-property: width;
    transition-duration: 2s;
    transition-timing-function: ease;
    transition-delay: 0s;
    height: 200px;
    border-top: 5px solid #663708;
}

.banner {
    width: 250px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-bottom: 9px;
    background-color: black;
}


.firstTimer {
    margin-left: 30px;
}
.timer {
    padding: 10px;
    margin-right: 15px;
    background-color: #144587;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    initial-letter: 0.2;
    color: white;
    z-index: -1;
}

.navbar-background-color {
    background-color: white;
}

.logo-color {
    color: #FFA626;
}

.navPadding {
    padding: 20px;
}

.nav-link-custom {
    font-family: 'roboto';
    color: #8f8ca8;
    text-transform: capitalize;
    font-weight: 500;
    line-height: 24px;
    font-size: 16px;
    border-bottom: 1px dotted transparent;
    margin: 10px 15px;
    cursor: pointer;
}
.nav-link-custom:hover {
    border-bottom: 1px dotted black;
    color: purple;
}

.cursor-pointer {
    cursor: pointer;
}

.color-white {
    color: white;
}

.dropdown-item:hover {
    cursor: pointer;
}

.smallScreenPadding {
    padding-bottom: 0;
}
.smallScreenMargins {
    margin-top: 0;
    margin-left: 0;
}

.header1 {
    font-family: 'roboto';
    font-size: 48px;
    font-weight: 700;
    text-transform: capitalize;
}

.darkMode {
    background-color: #ececf6;
}

@media only screen and (max-width: 992px) {
    .smallScreenPadding {
        padding-bottom: 60px;
    }
    .smallScreenMargins {
        margin-top: 55px;
        margin-left: 12px;
    }
}

.padding-bottom {
    padding-Bottom: 100px;
}

.background-color {
    background-color: #f6f6f6;
}

.section-padding {
    padding-top: 100px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}

.box {
    border-bottom: 1px solid #ededed;
    padding-bottom: 85px;
}
.leftBox {
    border-right: 1px solid #ededed;
}
.bottomBox {
    padding-top: 80px;
    border-bottom: none;
}

.right-offset-40 {
    padding-right: 40px;
}
.left-offset-40 {
    padding-left: 40px;
}

.media-text {
    color: #6d6d6d;
    font-family: 'roboto';
    font-size: 14px;
    margin: 0 0 10px;
}

.custom-bottom {
    position: absolute;
    bottom: 30px;
}

@media only screen and (max-width: 768px) {
    .box {
        border-bottom: none;
    }
    .leftBox {
        border-right: none;
    }
    .bottomBox {
        border-bottom: none;
        padding-top: 0;
    }

    .right-offset-40 {
        padding-right: 15px;
    }

    .left-offset-40 {
        padding-left: 15px;
    }
}


.landingBackground {
    background-image: url(/static/media/dawn.124d1bba.jpg);
    background-position: center;
    background-size: cover;
}
.orangeBackground {
    background-color: #FFA626;
}

.jumbo-custom {
    color: white;
    margin-bottom: 0;
    background: linear-gradient(45deg, rgba(242,135,5, 0.50) 50%, rgba(242,159,5,0.0) 50%);
    background-size: cover;

    /*background: rgba(204, 204, 204, 0.5);*/
    /*z-index: -2;*/
    /*opacity: 0.4;*/
}

.container-custom {
    height: 73.5vh;
}

.rounded-button {
    border-radius: 40px;
    background-color: white;
    padding: 10px 27px;
    font-family: 'roboto';
    font-size: 12px;
    font-weight: 500;
    color: #333;
    border-color: #ccc;
}
.rounded-button:hover {
    background-color: #ececf6;
}

.padding-top-100 {
    padding-top: 100px;
}

.home-header {
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    font-size: 5rem;
}

.jumboHeight {
    height: 25vh;
}
.jumboDisplay {
    display: none;
}

.cardWidth {
    width: 30rem;
}
.cardPadding {
    padding: 2em;
}

.iconPadding {
    padding: 1em;
}

.largeScreenMargin {
    margin-left: 1rem;
}

.smallScreenMarginTop {
    margin-top: 0;
}

.contactHeader {
    font-size: 48px;
    color: #ffffff;
    line-height: 1.4;
    text-align: center;
    font-family: 'roboto';
    font-weight: 700;
    font-style: normal;
}

.marginSection {
    margin: 7rem;
}

.formHeader {
    margin-bottom: 3rem;
    color: #333;
    font-weight: 600;
}

.inputPadding > input {
    padding: 1.4em 1em 1.4em 1em;
    margin-top: 1em;
}
.inputPadding > textarea {
    padding: 1.4em 1em 1.4em 1em;
    margin-top: 1em;
}

.iFrameStyles {
    margin-top: 6rem;
    margin-bottom: 6rem;
    width: 100%;
    height: 35vh;
}

@media only screen and (max-width: 1199.98px) {
    .jumboDisplay {
        display: block;
    }

    .largeScreenMargin {
        margin-left: 0;
    }

    .cardWidth {
        margin: 0 0.5em 0.5em;
    }

    .smallScreenMarginTop {
        margin-top: 3rem;
    }
    .marginSection {
        margin: 3rem;
    }

    .iFrameStyles {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
}


.services-opening-jumbo {
    height: 29.5vh;
}

.jobHeader {
    font-size: 48px;
    color: #333333;
    line-height: 1.4;
    font-family: 'roboto';
    font-weight: 700;
    font-style: normal;
}
.jobSecondHeader {
    font-size: 20px;
    color: #333333;
    line-height: 1.4;
    font-weight: 600;
    margin-top: 1rem;
}

.full-gray {
    width: 100vw;
    background-color: #ececf6;
}

@media (max-width: 998px) {
    .services-opening-jumbo {
        height: auto;
    }
}

.job-opening-jumbo {
    height: 29.5vh;
}
.pageMargins {
    margin: 6rem 0 6rem 0;
}

.marginLeft1 {
    margin-left: 1rem;
}

.jobCardMargins {
    margin-top: 2rem;
}

.paddedRow {
    padding: 18vh;
    margin-bottom: 9rem;
}

.jobHeader {
    font-size: 48px;
    color: #333333;
    line-height: 1.4;
    font-family: 'roboto';
    font-weight: 700;
    font-style: normal;
}
.jobSecondHeader {
    font-size: 23px;
    color: #333333;
    line-height: 1.4;
    font-weight: 600;
    margin-top: 1rem;
}

.job-button {
    margin-top: 1rem;
    padding: 1rem;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
}

@media only screen and (max-width: 1199.98px) {
    .jobCardMargins {
        margin-top: 1rem;
    }
}

@media (max-width: 998px) {
    .job-opening-jumbo {
        height: auto;
    }
}

.project-details-jumbo {
    height: 26vw;
}

.project-details-jumbo-header {
    color: white;
}

.project-details-jumbo-second-header {
    color: white;
}

.project-title-text {
    margin-top: 17vh;
}

.body-text-div {
    margin-top: 5vh;
}

@media (max-width: 998px) {
    .project-details-jumbo {
        height: auto;
    }

    .project-title-text {
        margin-top: 6vh;
    }

    .body-text-div {
        margin-top: 3vh;
    }
}

.project-details-jumbo {
    height: 26vw;
}

.project-details-jumbo-header {
    color: white;
}

.project-details-jumbo-second-header {
    color: white;
}

.project-title-text {
    margin-top: 17vh;
}

.body-text-div {
    margin-top: 5vh;
}

@media (max-width: 998px) {
    .project-details-jumbo {
        height: auto;
    }

    .project-title-text {
        margin-top: 6vh;
    }

    .body-text-div {
        margin-top: 3vh;
    }
}

.job-opening-detail-jumbo {
    height: 29.5vh;
}
.pageMargins {
    margin: 6rem 0 6rem 0;
}

.marginLeft1 {
    margin-left: 1rem;
}

.jobCardMargins {
    margin-top: 2rem;
}

.coloredRow-s {
    background-color: #F28705;
    padding: 12vh;
    margin-bottom: 9rem;
}

.jobHeader {
    font-size: 48px;
    color: #333333;
    line-height: 1.4;
    font-family: 'roboto';
    font-weight: 700;
    font-style: normal;
}
.jobSecondHeader {
    font-size: 23px;
    color: #333333;
    line-height: 1.4;
    font-weight: 600;
    margin-top: 1rem;
}

.job-button {
    margin-top: 1rem;
    padding: 1rem;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
}

@media only screen and (max-width: 1199.98px) {
    .jobCardMargins {
        margin-top: 1rem;
    }
}

@media (max-width: 998px) {
    .job-opening-detail-jumbo {
        height: auto;
    }
}

.project-card-size {
    width: 17vw;
    border-radius: 2px;
}

.project-card-shadow {
    box-shadow: 8px 5px 13px 1px rgba(150,150,150,0.4);
}

.project-card-text-indent {
    margin: 14px;
}

.margin-bottom-14 {
    margin-bottom: 14px;
}

@media only screen and (max-width: 1199.98px) {
    .project-card-size {
        width: 95vw;
        border-radius: 2px;
        margin-left: 2.5vw;
        margin-right: 2.5vw;
        margin-top: 2.5vw;
    }
}

.job-opening-jumbo {
    height: 29.5vh;
}
.pageMargins {
    margin: 6rem 0 6rem 0;
}

.marginLeft1 {
    margin-left: 1rem;
}

.jobCardMargins {
    margin-top: 2rem;
}

.coloredRow {
    background-color: #F28705;
    padding: 18vh;
    margin-bottom: 9rem;
}

.jobHeader {
    font-size: 48px;
    color: #333333;
    line-height: 1.4;
    font-family: 'roboto';
    font-weight: 700;
    font-style: normal;
}
.jobSecondHeader {
    font-size: 23px;
    color: #333333;
    line-height: 1.4;
    font-weight: 600;
    margin-top: 1rem;
}

.job-button {
    margin-top: 1rem;
    padding: 1rem;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
}

@media only screen and (max-width: 1199.98px) {
    .jobCardMargins {
        margin-top: 1rem;
    }
}

.privacy-policy > * {
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.07px;
    line-height: 1.7em;
}

#root {
    
}
