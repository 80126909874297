.project-card-size {
    width: 17vw;
    border-radius: 2px;
}

.project-card-shadow {
    -webkit-box-shadow: 8px 5px 13px 1px rgba(150,150,150,0.4);
    -moz-box-shadow: 8px 5px 13px 1px rgba(150,150,150,0.4);
    box-shadow: 8px 5px 13px 1px rgba(150,150,150,0.4);
}

.project-card-text-indent {
    margin: 14px;
}

.margin-bottom-14 {
    margin-bottom: 14px;
}

@media only screen and (max-width: 1199.98px) {
    .project-card-size {
        width: 95vw;
        border-radius: 2px;
        margin-left: 2.5vw;
        margin-right: 2.5vw;
        margin-top: 2.5vw;
    }
}
