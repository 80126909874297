.job-opening-jumbo {
    height: 29.5vh;
}
.pageMargins {
    margin: 6rem 0 6rem 0;
}

.marginLeft1 {
    margin-left: 1rem;
}

.jobCardMargins {
    margin-top: 2rem;
}

.coloredRow {
    background-color: #F28705;
    padding: 18vh;
    margin-bottom: 9rem;
}

.jobHeader {
    font-size: 48px;
    color: #333333;
    line-height: 1.4;
    font-family: 'roboto';
    font-weight: 700;
    font-style: normal;
}
.jobSecondHeader {
    font-size: 23px;
    color: #333333;
    line-height: 1.4;
    font-weight: 600;
    margin-top: 1rem;
}

.job-button {
    margin-top: 1rem;
    padding: 1rem;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
}

@media only screen and (max-width: 1199.98px) {
    .jobCardMargins {
        margin-top: 1rem;
    }
}
