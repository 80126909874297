.popup {
    position: fixed;
    left: 5em;
    top: 0em;
    z-index: 2020;
    overflow: hidden;
    transition-property: width;
    transition-duration: 2s;
    transition-timing-function: ease;
    transition-delay: 0s;
    height: 200px;
    border-top: 5px solid #663708;
}

.banner {
    width: 250px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-bottom: 9px;
    background-color: black;
}


.firstTimer {
    margin-left: 30px;
}
.timer {
    padding: 10px;
    margin-right: 15px;
    background-color: #144587;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    initial-letter: 0.2;
    color: white;
    z-index: -1;
}
