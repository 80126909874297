.jumboHeight {
    height: 25vh;
}
.jumboDisplay {
    display: none;
}

.cardWidth {
    width: 30rem;
}
.cardPadding {
    padding: 2em;
}

.iconPadding {
    padding: 1em;
}

.largeScreenMargin {
    margin-left: 1rem;
}

.smallScreenMarginTop {
    margin-top: 0;
}

.contactHeader {
    font-size: 48px;
    color: #ffffff;
    line-height: 1.4;
    text-align: center;
    font-family: 'roboto';
    font-weight: 700;
    font-style: normal;
}

.marginSection {
    margin: 7rem;
}

.formHeader {
    margin-bottom: 3rem;
    color: #333;
    font-weight: 600;
}

.inputPadding > input {
    padding: 1.4em 1em 1.4em 1em;
    margin-top: 1em;
}
.inputPadding > textarea {
    padding: 1.4em 1em 1.4em 1em;
    margin-top: 1em;
}

.iFrameStyles {
    margin-top: 6rem;
    margin-bottom: 6rem;
    width: 100%;
    height: 35vh;
}

@media only screen and (max-width: 1199.98px) {
    .jumboDisplay {
        display: block;
    }

    .largeScreenMargin {
        margin-left: 0;
    }

    .cardWidth {
        margin: 0 0.5em 0.5em;
    }

    .smallScreenMarginTop {
        margin-top: 3rem;
    }
    .marginSection {
        margin: 3rem;
    }

    .iFrameStyles {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
}

