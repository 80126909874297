.project-details-jumbo {
    height: 26vw;
}

.project-details-jumbo-header {
    color: white;
}

.project-details-jumbo-second-header {
    color: white;
}

.project-title-text {
    margin-top: 17vh;
}

.body-text-div {
    margin-top: 5vh;
}

@media (max-width: 998px) {
    .project-details-jumbo {
        height: auto;
    }

    .project-title-text {
        margin-top: 6vh;
    }

    .body-text-div {
        margin-top: 3vh;
    }
}
