.services-opening-jumbo {
    height: 29.5vh;
}

.jobHeader {
    font-size: 48px;
    color: #333333;
    line-height: 1.4;
    font-family: 'roboto';
    font-weight: 700;
    font-style: normal;
}
.jobSecondHeader {
    font-size: 20px;
    color: #333333;
    line-height: 1.4;
    font-weight: 600;
    margin-top: 1rem;
}

.full-gray {
    width: 100vw;
    background-color: #ececf6;
}

@media (max-width: 998px) {
    .services-opening-jumbo {
        height: auto;
    }
}
